<template>

  <v-container>
    <v-img :src="require('../assets/nc-logo.svg')" class="my-3" contain height="300" />
  </v-container>

</template>

<script>
import { defineComponent } from 'vue';
import axios from 'axios';

// Components
// ...

export default defineComponent({
  name: 'HomeView',
  components: {},
  data() {
    return {
      isBusy: false,
      contentSelected: false,
      categories: []
    }
  },
  async mounted() {
    //await this.getCategoriesAsync();
  },
  setup() {

  },
  methods: {
    async getCategoriesAsync() {
      this.isBusy = true;
      try {
        let response = await axios.get("https://localhost:7096/categories");
        this.categories = response.data.map(x => { return { title: x.name, value: x.id }; });
      }
      catch (ex) {
        console.warn("Failed to get data from the API");
      }

      this.isBusy = false;
    }

  }
});
</script>
