<template>
  <v-app app>
    <v-sheet>
      <v-app-bar color="background" elevation="0" border="bottom">

        <v-app-bar-title>
          <router-link :to="{name: home}" class="text-decoration-none">
            <img :src="require('./assets/nc-logo.svg')" style="height: 2rem" class="align-middle" />
            Neptune Century
          </router-link>

        </v-app-bar-title>
        <v-btn :to="{name: 'home'}">Home</v-btn>

        <template v-slot:append>
          <v-btn icon="mdi-brightness-6" @click="toggleTheme" title="Toggle light and dark theme"></v-btn>
        </template>
      </v-app-bar>
    </v-sheet>


    <v-main>
      <router-view />
    </v-main>

    <v-footer app bottom fixed>
      <v-row>
        <v-col cols="12">
          <h5>Links</h5>
          <p>
            <a href="http://www.mxguarddog.com/">Spam blockers</a>
          </p>
        </v-col>
      </v-row>

    </v-footer>
  </v-app>
</template>

<script>

import { useTheme } from 'vuetify';

export default {
  name: 'App',
  setup() {
    const theme = useTheme();
    return {
      theme
    };
  },
  data() {
    return {

    };
  },
  created() {
    let currentTheme = null;

    // Get the theme from the browser local storage. If not available, we simply
    // check the OS default theme.
    if (localStorage) {
      // Access local storage to get saved theme
      let savedTheme = localStorage.getItem("ncs:theme");
      if (savedTheme) {
        // Set our default to the saved theme
        currentTheme = savedTheme;

      }

    }

    if (!currentTheme) {
      // If there is no default, set default theme based on OS
      const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
      if (darkThemeMq.matches) {
        // Theme set to dark.
        currentTheme = "dark";

      } else {
        // Theme set to light.
        currentTheme = "light";

      }

    }

    // Set theme
    this.theme.global.name.value = currentTheme;

  },
  methods: {
    toggleTheme() {
      let currentTheme = this.theme.global.current.value.dark ? "light" : "dark";
      // Set theme
      this.theme.global.name.value = currentTheme;
      // Store saved theme
      if (localStorage) {
        localStorage.setItem("ncs:theme", currentTheme);
      }
    }
  }
}
</script>
